const reports = {
    tripAndDriving: {
        key: 'tripAndDriving',
        categoryTranslationKey: 'reportCategoryTripAndDriving',
        categoryIconClass: 'fa fa-tachometer',
        reports: {
            historyOfDriversNotes: {
                nameTranslationKey: 'reportNameHistoryOfDriversNotes',
                objectivesTranslationKey: 'reportDescHistoryOfDriversNotes',
                type: 'historyOfDriversNotes',
                required: ['startDate', 'endDate'],
                authorizedRoles: ['dispatcher', 'carrier_manager'],
            },
            distance: {
                nameTranslationKey: 'reportNameDistance',
                objectivesTranslationKey: 'reportDescDistance',
                type: 'distance',
                required: ['startDate', 'endDate', 'busNo'],
                authorizedRoles: ['manager', 'agent', 'dispatcher', 'carrier_manager'],
            },
            dailyDistance: {
                nameTranslationKey: 'reportNameDailyDistance',
                objectivesTranslationKey: 'reportDescDailyDistance',
                type: 'dailyDistance',
                required: ['startDate'],
                authorizedRoles: ['manager', 'agent', 'dispatcher', 'carrier_manager'],
            },
            routesDuration: {
                nameTranslationKey: 'reportNameRunsDuration',
                objectivesTranslationKey: 'reportDescRunsDuration',
                type: 'routesDuration',
                required: ['startDate', 'endDate'],
                authorizedRoles: ['manager', 'agent', 'dispatcher', 'carrier_manager'],
            },
            institutionArrival: {
                nameTranslationKey: 'reportNameInstitutionArrival',
                objectivesTranslationKey: 'reportDescInstitutionArrival',
                type: 'institutionArrival',
                required: ['startDate', 'endDate'],
                optional: ['institutionId'],
                selectDateRangeTranslationKey: 'selectDateRangeOneWeek',
                authorizedRoles: ['manager', 'agent', 'dispatcher', 'carrier_manager'],
            },
            vehiclesUsageDate: {
                nameTranslationKey: 'reportNameVehiclesUsageByDate',
                objectivesTranslationKey: 'reportDescVehiclesUsageByDate',
                type: 'vehiclesUsageDate',
                required: ['startDate'],
                authorizedRoles: ['dispatcher', 'carrier_manager'],
            },
            routeStatus: {
                nameTranslationKey: 'reportNameRouteStatus',
                objectivesTranslationKey: 'reportDescRouteStatus',
                type: 'routeStatus',
                required: ['startDate'],
                authorizedRoles: ['dispatcher', 'carrier_manager'],
            },
            irregularTrips: {
                nameTranslationKey: 'reportNameMisuse',
                objectivesTranslationKey: 'reportDescIrregularTrips',
                type: 'irregularTrips',
                required: ['startDate'],
                optional: ['busNo', 'tripAnalysisTypes'],
                authorizedRoles: ['manager', 'agent', 'dispatcher', 'carrier_manager'],
                new: true,
            },
            realTimes: {
                nameTranslationKey: 'reportNameRealTimes',
                objectivesTranslationKey: 'reportDescRealTimes',
                type: 'realTimes',
                required: ['startDate'],
                authorizedRoles: ['manager', 'agent'],
            },
            speedLocation: {
                nameTranslationKey: 'reportNameSpeedLocation',
                objectivesTranslationKey: 'reportDescSpeedLocation',
                type: 'speedLocation',
                required: ['startDate', 'startTime', 'endTime', 'latitude', 'longitude'],
                optional: ['radius'],
                authorizedRoles: ['manager', 'agent', 'dispatcher', 'carrier_manager'],
            },
        },
    },
    student: {
        key: 'student',
        categoryTranslationKey: 'students',
        categoryIconClass: 'fa fa-users',
        reports: {
            absenteeismByInstitution: {
                nameTranslationKey: 'reportNameAbsents',
                objectivesTranslationKey: 'reportDescAbsents',
                type: 'absenteeismByInstitution',
                required: ['startDate', 'endDate'],
                optional: ['institutionId', 'minAbsentRate', 'maxAbsentRate'],
                authorizedRoles: ['manager', 'agent'],
            },
            absenteeismByBus: {
                nameTranslationKey: 'reportNameAbsenceByBus',
                objectivesTranslationKey: 'reportDescAbsents',
                type: 'absenteeismByBus',
                required: ['startDate', 'endDate'],
                optional: ['busNo', 'minAbsentRate', 'maxAbsentRate'],
                authorizedRoles: ['manager', 'agent', 'dispatcher', 'carrier_manager'],
            },
            vehiclesOccupancy: {
                nameTranslationKey: 'reportNameVehiclesOccupancy',
                objectivesTranslationKey: 'reportDescVehiclesOccupancy',
                type: 'vehiclesOccupancy',
                required: ['startDate', 'endDate'],
                authorizedRoles: ['manager', 'agent'],
            },
            guardianAccountsNotActivated: {
                nameTranslationKey: 'reportGuardianAccountsNotActivated',
                objectivesTranslationKey: 'guardianAccountsNotActivatedReportDesc',
                type: 'guardianAccountsNotActivated',
                required: ['generationConfirmed'],
                authorizedRoles: ['manager', 'agent'],
            },
            cardmisuse: {
                nameTranslationKey: 'cardMisuseReport',
                objectivesTranslationKey: 'cardMisuseReportDesc',
                type: 'cardmisuse',
                required: ['startDate', 'endDate', 'vehicleTypes'],
                authorizedRoles: ['manager', 'agent'],
            },
            stopmisuse: {
                nameTranslationKey: 'stopMisuseReport',
                objectivesTranslationKey: 'stopMisuseReportDesc',
                type: 'stopmisuse',
                required: ['startDate', 'endDate'],
                authorizedRoles: ['manager', 'agent'],
            },
            absenceAnnounced: {
                nameTranslationKey: 'reportNameAbsenceAnnounced',
                objectivesTranslationKey: 'reportDescAbsenceAnnounced',
                type: 'absenceAnnounced',
                required: ['startDate'],
                authorizedRoles: ['manager', 'agent', 'dispatcher', 'carrier_manager'],
            },
            disciplinary: {
                nameTranslationKey: 'reportNameDisciplinary',
                objectivesTranslationKey: 'reportDescDisciplinary',
                type: 'disciplinary',
                required: ['startDate', 'endDate'],
                authorizedRoles: ['manager', 'agent', 'dispatcher', 'carrier_manager'],
            },
            cardsRead: {
                nameTranslationKey: 'reportNameCardsRead',
                objectivesTranslationKey: 'reportDescCardsRead',
                type: 'cardsRead',
                required: ['startDate', 'endDate'],
                authorizedRoles: ['manager', 'agent', 'dispatcher', 'carrier_manager'],
            },
            transportation: {
                nameTranslationKey: 'reportNameTransportation',
                objectivesTranslationKey: 'reportDescTransportation',
                type: 'transportation',
                required: ['institutionId'],
                authorizedRoles: ['manager', 'agent', 'observer'],
            },
        },
    },
    internal: {
        key: 'internal',
        categoryTranslationKey: 'reportCategoryInternal',
        categoryIconClass: 'fa fa-bar-chart',
        reports: {
            tabletUsage: {
                nameTranslationKey: 'reportNameTabletUsageByBus',
                objectivesTranslationKey: 'reportDescTabletUsage',
                type: 'tabletUsage',
                required: ['startDate', 'endDate'],
                authorizedRoles: ['manager', 'agent', 'dispatcher', 'carrier_manager'],
            },
            tabletUsageRoute: {
                nameTranslationKey: 'reportNameTabletUsageByRun',
                objectivesTranslationKey: 'reportDescTabletUsage',
                type: 'tabletUsageRoute',
                required: ['startDate', 'endDate'],
                authorizedRoles: ['manager', 'agent', 'dispatcher', 'carrier_manager'],
            },
            tabletUsageOperator: {
                nameTranslationKey: 'reportNameTabletUsageByOperator',
                objectivesTranslationKey: 'reportDescTabletUsageByOperator',
                type: 'tabletUsageOperator',
                required: ['startDate', 'endDate'],
                authorizedRoles: ['manager', 'agent'],
            },
            devicesUsageDate: {
                nameTranslationKey: 'reportNameDevicesUsageByDate',
                objectivesTranslationKey: 'reportDescDevicesUsageByDate',
                type: 'devicesUsageDate',
                required: ['startDate'],
                authorizedRoles: ['manager', 'agent', 'dispatcher', 'carrier_manager'],
            },
            // TODO MTR-6527 : cleanup code for these two reports
            // misuse: {
            //     nameTranslationKey: 'reportNameMisuse',
            //     objectivesTranslationKey: 'reportDescMisuse',
            //     type: 'misuse',
            //     required: ['startDate'],
            //     authorizedRoles: ['manager', 'agent', 'dispatcher', 'carrier_manager'],
            // },
            // misuseBus: {
            //     nameTranslationKey: 'reportNameMisuseBus',
            //     objectivesTranslationKey: 'reportDescMisuseBus',
            //     type: 'misuseBus',
            //     required: ['startDate', 'endDate', 'busNo'],
            //     authorizedRoles: ['manager', 'agent', 'dispatcher', 'carrier_manager'],
            // },
            unusedStopsReport: {
                nameTranslationKey: 'unusedStopsReport',
                objectivesTranslationKey: 'unusedStopsReportDesc',
                type: 'unusedStopsReport',
                required: ['startDate', 'endDate'],
                authorizedRoles: ['manager', 'agent'],
            },
            runsPerBus: {
                nameTranslationKey: 'reportNameRunsPerBus',
                objectivesTranslationKey: 'reportDescRunsPerBus',
                type: 'runsPerBus',
                required: [],
                authorizedRoles: ['manager', 'agent'],
            },
            cancelledRuns: {
                nameTranslationKey: 'reportNameCancelledRuns',
                objectivesTranslationKey: 'reportDescCancelledRuns',
                type: 'cancelledRuns',
                required: ['startDate', 'endDate'],
                authorizedRoles: ['manager', 'agent'],
            },
        },
    },
    admin: {
        key: 'admin',
        categoryTranslationKey: 'reportCategoryAdmin',
        categoryIconClass: 'fa fa-clipboard',
        reports: {
            clientBuses: {
                nameTranslationKey: 'reportNameClientBuses',
                objectivesTranslationKey: 'reportDescClientBuses',
                type: 'clientBuses',
                required: [],
                authorizedRoles: ['admin', 'helpdesk'],
            },
        },
    },
};

/**
 * Create user reports based on the logged user role
 * @param {String} loggedUserRole
 */
export const createUserReports = (loggedUserRole) => {
    const userReports = {};

    // Iterate through each category in the reports object
    for (const [categoryKey, category] of Object.entries(reports)) {
        // Check if the category has any reports accessible to the user
        const isAccessible = Object.values(category.reports).some((report) => report.authorizedRoles.includes(loggedUserRole));
        if (isAccessible !== true) {
            continue; // Skip this category if no reports are accessible
        }

        // Create a new category in userReports
        userReports[categoryKey] = {
            ...reports[categoryKey], // Copy category metadata
            reports: Object.keys(reports[categoryKey].reports).reduce((accessibleReports, reportKey) => {
                const currentReport = reports[categoryKey].reports[reportKey];
                // Check if the current report is accessible to the user
                if (currentReport.authorizedRoles.includes(loggedUserRole)) {
                    accessibleReports[reportKey] = { ...currentReport };
                }
                return accessibleReports;
            }, {}),
        };
    }

    return userReports;
};
